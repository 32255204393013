import gql from "graphql-tag";

export const GET_UNLABELLED_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!) {
    pose_threejs_aggregate(distinct_on: id, where: {isLabelled: {_eq: "Not Labelled"}, id: {_gte: $startId, _lte: $endId}}) {
    aggregate {
      count
    }
    nodes {
      id
    }
  }
}
`;

export const TABLE = "pose_threejs"