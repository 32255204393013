import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './Components/Home';
import Helper from './Components/Helper'

const Routes = () => (
    <Router>
        <Route exact path="/imageid=:imageid" component={Home} />
        <Route exact path="/helper" component={Helper} />
    </Router>
);

export default Routes;